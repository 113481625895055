import PropTypes from "prop-types";
import React from "react";
import { graphql } from "gatsby";
import { ThemeContext } from "../layouts";
import Blog from "../components/Blog";
import BlogCondensed from "../components/Blog/BlogCondensed";
import Hero from "../components/Hero";
import Seo from "../components/Seo";
import deviceImage from "../images/technologies/devices.jpg";
import ioteryLogo from "../images/iotery.png";
import Style from "./index.css";

import Slider from "react-slick";

function importAll(r) {
  let images = {};
  r.keys().map((item, index) => {
    images[item.replace("./", "")] = r(item);
  });
  return images;
}

const techImages = importAll(
  require.context("../images/technologies", false, /\.(png|jpe?g|svg)$/)
);

const networkImages = importAll(require.context("../images/network", false, /\.(png|jpe?g|svg)$/));

let technologyFilenames = [
  "alexa.png",
  "android.gif",
  "angular.png",
  "arm.gif",
  "aws.png",
  "azure-iot.png",
  "azure.png",
  "ble.png",
  "bigquery.png",
  "cplusplus.png",
  "cassandra.jpg",
  "digitalocean.png",
  "docker.png",
  "gcp.png",
  "github.png",
  "gitlab.png",
  "go.png",
  "googleassistant.png",
  "googlehome.png",
  "ifttt.jpg",
  "intel.gif",
  "ios.jpg",
  "iotery.png",
  "kubernetes.png",
  "kvaser.png",
  "keras.png",
  "linux.gif",
  "lora.png",
  "lte.jpg",
  "microchip.gif",
  "mysql.png",
  "node.png",
  "oracle.jpg",
  "python.png",
  "postgres.gif",
  "raspberrypi.png",
  "react.png",
  "redhat.png",
  "rs232.png",
  "rust.jpg",
  "siri.jpg",
  "st.gif",
  "scikit.png",
  "stripe.png",
  "ti.png",
  "tensorflow.jpg",
  "teradata.png",
  "wifi.gif",
  "worldpay.png",
  "zigbee.jpg",
  "zwave.png"
];

let networkFilenames = [
  "brunswick.jpg",
  "healthqrs.jpg",
  "ge.png",
  "iotery.png",
  "kroger.png",
  "mercury.jpg",
  "smt.jpg",
  "bw.jpg",
  "cvg.jpg",
  "cardinal.jpg",
  "belkin.jpg",
  "cartech.jpg",
  "dupont.png",
  "foodforest.jpg",
  "ic3d.png",
  "microchip.png",
  "osu.jpg",
  "searay.jpg",
  "utd.jpg",
  "aeris.jpg"
];

let settings = {
  dots: false,
  infinite: true,
  speed: 4000,
  slidesToShow: 6,
  slidesToScroll: 2,
  autoplay: true,
  autoplaySpeed: 0,
  cssEase: "ease-out",
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
        dots: true
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 3
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3
      }
    }
  ]
};

let networkSettings = {
  dots: false,
  infinite: true,
  speed: 2000,
  slidesToShow: 4,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 0,
  cssEase: "ease-out",
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        infinite: true,
        dots: true
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 2
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }
  ]
};

const Spacer = ({ space = 1 }) => (
  <div
    style={{
      display: "flex",
      height: `${space}rem`,
      minHeight: `${space}rem`
    }}
  />
);

class IndexPage extends React.Component {
  separator = React.createRef();

  scrollToContent = e => {
    this.separator.current.scrollIntoView({ block: "start", behavior: "smooth" });
  };

  render() {
    const {
      data: {
        posts: { edges: posts = [] },
        bgDesktop: {
          resize: { src: desktop }
        },
        bgTablet: {
          resize: { src: tablet }
        },
        bgMobile: {
          resize: { src: mobile }
        },
        site: {
          siteMetadata: { facebook }
        }
      }
    } = this.props;

    const backgrounds = {
      desktop,
      tablet,
      mobile
    };

    console.log(mobile);

    return (
      <React.Fragment>
        <Style></Style>
        <ThemeContext.Consumer>
          {theme => (
            <Hero scrollToContent={this.scrollToContent} backgrounds={backgrounds} theme={theme} />
          )}
        </ThemeContext.Consumer>

        <hr ref={this.separator} />

        <section className="section">
          <div style={{ display: "flex", width: "100%", justifyContent: "center" }}>
            <div id="we-know-iot">
              <h1 className="sectionHeader">We're connected.</h1>
              <Spacer />
              <p>
                Leverage our years of experience in designing, architecting, and deploying Internet
                of Things and connected products and lower the activation energy to a profitable
                connected ecosystem.
              </p>
            </div>
          </div>

          <div
            className="devicesContainer"
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              backgroundImage: `url('data:image/jpeg;base64,/9j/4QAYRXhpZgAASUkqAAgAAAAAAAAAAAAAAP/sABFEdWNreQABAAQAAAA8AAD/4QMxaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wLwA8P3hwYWNrZXQgYmVnaW49Iu+7vyIgaWQ9Ilc1TTBNcENlaGlIenJlU3pOVGN6a2M5ZCI/PiA8eDp4bXBtZXRhIHhtbG5zOng9ImFkb2JlOm5zOm1ldGEvIiB4OnhtcHRrPSJBZG9iZSBYTVAgQ29yZSA1LjYtYzE0NSA3OS4xNjM0OTksIDIwMTgvMDgvMTMtMTY6NDA6MjIgICAgICAgICI+IDxyZGY6UkRGIHhtbG5zOnJkZj0iaHR0cDovL3d3dy53My5vcmcvMTk5OS8wMi8yMi1yZGYtc3ludGF4LW5zIyI+IDxyZGY6RGVzY3JpcHRpb24gcmRmOmFib3V0PSIiIHhtbG5zOnhtcD0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wLyIgeG1sbnM6eG1wTU09Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9tbS8iIHhtbG5zOnN0UmVmPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvc1R5cGUvUmVzb3VyY2VSZWYjIiB4bXA6Q3JlYXRvclRvb2w9IkFkb2JlIFBob3Rvc2hvcCBDQyAyMDE5IChNYWNpbnRvc2gpIiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOjNDMkE2RTdFQkEzMjExRTlBNkY0OTA0RTBGNEYzMDlEIiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOjNDMkE2RTdGQkEzMjExRTlBNkY0OTA0RTBGNEYzMDlEIj4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6M0MyQTZFN0NCQTMyMTFFOUE2RjQ5MDRFMEY0RjMwOUQiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6M0MyQTZFN0RCQTMyMTFFOUE2RjQ5MDRFMEY0RjMwOUQiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz7/7gAOQWRvYmUAZMAAAAAB/9sAhAAGBAQEBQQGBQUGCQYFBgkLCAYGCAsMCgoLCgoMEAwMDAwMDBAMDg8QDw4MExMUFBMTHBsbGxwfHx8fHx8fHx8fAQcHBw0MDRgQEBgaFREVGh8fHx8fHx8fHx8fHx8fHx8fHx8fHx8fHx8fHx8fHx8fHx8fHx8fHx8fHx8fHx8fHx//wAARCAMAAAwDAREAAhEBAxEB/8QAUAABAQEAAAAAAAAAAAAAAAAAAAEIAQEAAAAAAAAAAAAAAAAAAAAAEAEBAQEBAAAAAAAAAAAAAAAAEQEhQREBAAAAAAAAAAAAAAAAAAAAAP/aAAwDAQACEQMRAD8A1SAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAABgAGggKABQAQF8BAAUDgJQAAAAAAAKACQFABKCgkBQQDQAAAQAAEAAAoJQSgAgFBAKCAAlAvAIAAABoICgkBAAUAEAAAAABdAgKACAoAAAKCAoEABN0EoIAAAACAAAAnoGgAAAgEAAoKCAugnQUEoKCQFAAAB/9k=')`
            }}
          >
            <img
              alt="devices"
              src={deviceImage} //"/images/technologies/devices.jpg"
              style={{ width: "100%", height: "100% !important" }}
            />
          </div>
        </section>

        <section className="section">
          <div style={{ justifyContent: "center", textAlign: "center" }}>
            <h1 className="sectionHeader">We do what works for you.</h1>
            <Spacer></Spacer>
            <p>
              There is no "one size fits all" IoT solution. Here is some of the tech we know and
              use.
            </p>
            <Spacer space={3}></Spacer>
          </div>
          <Spacer space={3}></Spacer>
          <div>
            <Slider {...settings}>
              {technologyFilenames.map(t => {
                return (
                  <div key={t} style={{ width: 100 }}>
                    <img src={techImages[t]} width={100} style={{ marginLeft: "20%" }} />
                  </div>
                );
              })}
            </Slider>
          </div>
          <Spacer space={5}></Spacer>
        </section>

        <section className="section" style={{ background: "whitesmoke" }}>
          {/* <div className="container"><Content source={body} /></div> */}

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              textAlign: "center"
            }}
          >
            <h1 className="sectionHeader">Need it fast?</h1>
            <Spacer></Spacer>
            <p style={{ maxWidth: 700, alignSelf: "center" }}>
              We support{" "}
              <a href="https://iotery.io" target="_blank">
                Iotery.io
              </a>
              , a rapid development, cloud-based IoT service that is designed for easy management
              and fast deployment of your connected products. Best of all, it is flexible enough to
              work with all different types of connected devices. Need something else? We have you
              covered with Azure, AWS, or GCP IoT products, too.
            </p>
            <div style={{}}>
              {" "}
              <a href="https://iotery.io" target="_blank">
                <img alt="devices" src={ioteryLogo} style={{ width: "50%" }} />
              </a>
            </div>

            <Spacer space={3}></Spacer>
          </div>
        </section>

        <section className="section">
          <div style={{ justifyContent: "center", textAlign: "center" }}>
            <h1 className="sectionHeader">Our Connected Network</h1>
            <Spacer></Spacer>
            <p>With our customers, experience, and partner network, we've got you covered.</p>
            <Spacer space={3}></Spacer>
          </div>
          <Spacer space={3}></Spacer>
          <div>
            <Slider {...networkSettings}>
              {networkFilenames.map(t => {
                return (
                  <div key={t} style={{ width: 300 }}>
                    <img src={networkImages[t]} width={300} style={{ marginLeft: "20%" }} />
                  </div>
                );
              })}
            </Slider>
          </div>
          <Spacer space={5}></Spacer>
        </section>

        <hr ref={this.separator} />

        <section className="section">
          <div style={{ justifyContent: "center", textAlign: "center" }}>
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "center",
                flexDirection: "column"
              }}
            >
              <h1 className="sectionHeader">Get to know us. </h1>
              <Spacer></Spacer>
              <p>
                Listen to a recent podcast on the Schmidt List about the Security of Connected
                things by Technicity's Principle System Architect, BJ Yurkovich.
              </p>
              <Spacer space={3}></Spacer>
              <div style={{ textAlign: "center", justifySelf: "center" }}>
                <iframe
                  src="https://widget.spreaker.com/player?episode_id=21413682&theme=light&autoplay=false&playlist=false&cover_image_url=https%3A%2F%2Fd3wo5wojvuv7l.cloudfront.net%2Fimages.spreaker.com%2Foriginal%2Fda790bc1cbc0a3f21f402f65b43d57ab.jpg"
                  width="100%"
                  height="400px"
                  frameBorder="0"
                ></iframe>
                <script async src="https://widget.spreaker.com/widgets.js"></script>
              </div>
            </div>
          </div>
          <Spacer space={5}></Spacer>
        </section>

        <section className="section">
          <div style={{ justifyContent: "center", textAlign: "center" }}>
            <h1 className="sectionHeader">Case Studies</h1>
            <Spacer></Spacer>
            <p>
              This ain't our first rodeo. Learn about some of the larger projects that we have lived
              through.{" "}
            </p>
            <Spacer space={3}></Spacer>
          </div>
          <Spacer space={3}></Spacer>
          <div>
            <ThemeContext.Consumer>
              {theme => <BlogCondensed posts={posts} theme={theme} />}
            </ThemeContext.Consumer>
          </div>
          <Spacer space={5}></Spacer>
        </section>

        <Seo facebook={facebook} />

        <style jsx>{`
          hr {
            margin: 0;
            border: 0;
          }
        `}</style>
      </React.Fragment>
    );
  }
}

IndexPage.propTypes = {
  data: PropTypes.object.isRequired
};

export default IndexPage;

//eslint-disable-next-line no-undef
export const query = graphql`
  query IndexQuery {
    posts: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "//posts/[0-9]+.*--/" } }
      sort: { fields: [fields___prefix], order: DESC }
    ) {
      edges {
        node {
          excerpt
          fields {
            slug
            prefix
          }
          frontmatter {
            title
            category
            author
            cover {
              children {
                ... on ImageSharp {
                  fluid(maxWidth: 800, maxHeight: 360) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
      }
    }
    site {
      siteMetadata {
        facebook {
          appId
        }
      }
    }
    bgDesktop: imageSharp(fluid: { originalName: { regex: "/hero-background/" } }) {
      resize(width: 1200, quality: 90, cropFocus: CENTER) {
        src
      }
    }
    bgTablet: imageSharp(fluid: { originalName: { regex: "/hero-background/" } }) {
      resize(width: 800, height: 1100, quality: 90, cropFocus: CENTER) {
        src
      }
    }
    bgMobile: imageSharp(fluid: { originalName: { regex: "/hero-background/" } }) {
      resize(width: 450, height: 850, quality: 90, cropFocus: CENTER) {
        src
      }
    }
  }
`;

//hero-background
