import PropTypes from "prop-types";
import React from "react";

import Item from "./ItemCondensed";

const BlogCondensed = props => {
  const { posts, theme } = props;

  return (
    <React.Fragment>
      <main className="main">
        <div className="blog-container">
          {posts.map(post => {
            const {
              node,
              node: {
                fields: { slug }
              }
            } = post;
            return (
              <div className="item">
                <Item key={slug} post={node} theme={theme} />
              </div>
            );
          })}
        </div>
      </main>

      {/* --- STYLES --- */}
      <style jsx>{`
        .blog-container {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          justify-content: center;
        }

        .item {
          max-width: 300px;
        }

        .main {
          padding: 0 ${theme.space.inset.default};
        }

        ul {
          list-style: none;
          margin: 0 auto;
          padding: ${`calc(${theme.space.default} * 1.5) 0 calc(${theme.space.default} * 0.5)`};
        }

        @above tablet {
          .main {
            padding: 0 ${`0 calc(${theme.space.default} * 1.5)`};
          }
          ul {
            max-width: ${theme.text.maxWidth.tablet};
          }
        }
        @above desktop {
          ul {
            max-width: ${theme.text.maxWidth.desktop};
          }
        }
      `}</style>
    </React.Fragment>
  );
};

BlogCondensed.propTypes = {
  posts: PropTypes.array.isRequired,
  theme: PropTypes.object.isRequired
};

export default BlogCondensed;
